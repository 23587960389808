import React, {
	useContext, createContext, useState, useMemo, useEffect, useCallback, useRef
} from "react";
import _ from "lodash";
import axios from "axios";
import {
	server, masterKey, authServer, logonDelay, webUrl
} from "common/app-settings";
import useDialog from "hooks/useDialog";
import { infoDialog } from "components/dialogs/AlertDialog";
import { io } from "socket.io-client";
import packageJson from "../../package.json";

const LoginContext = createContext();
const SOCKET_TIMEOUT = 1000 * 60 * 10; // 10 mins

/**
 * This component uses the axios component directly instead of the api hook.
 * This ie because it has to do a few different things, which would have added
 * needless complexity to the useApi hook
*/
export const LoginProvider = ({ children }) => {
	const [user, setUser] = useState();
	const [token, setToken] = useState(masterKey);
	const isBrowser = useMemo(() => typeof window !== "undefined", []);
	const intRef = useRef();
	const socketIOClient = useRef();
	const closeTimeoutRef = useRef();
	const isRefreshing = useRef(false);
	const refreshTimeout = useRef();
	const { presentDialog, dismissDialog } = useDialog();

	const isLoggedIn = useMemo(() => token !== masterKey, [token]);
	const getClientId = useCallback(
		() => axios.get(`${authServer}auth/getClientId`).then((response) => response.data.clientId),
		[]
	);

	const checkLogin = useCallback(() => {
		if (isLoggedIn && _.isEmpty(user)) {
			axios.get(`${server}/auth/token`, { headers: customHeaders })
				.then(({ data: usr }) => {
					window.localStorage.setItem("gatsbyUser", JSON.stringify(usr));
					setUser(usr);
				})
				.catch((e) => {
					setUser([]);
					dismissDialog();
					console.error(e);
					presentDialog(infoDialog("Error", `Could not get token, please log in again ${e}`));
				});
		}
	}, [isLoggedIn, customHeaders, presentDialog, user, dismissDialog]);

	const getRefreshToken = useCallback(async () => {
		const refreshToken = window.localStorage.getItem("refreshToken");
		const clientId = await getClientId().catch(() => undefined); // Don't care about the error
		if (!isLoggedIn && refreshToken && !isRefreshing.current && clientId) {
			window.localStorage.removeItem("refreshToken");
			isRefreshing.current = true;
			const refreshHeaders = {
				Authorization: `Bearer ${refreshToken}`,
				"x-client-id": clientId
			};
			const { data } = await axios.get(`${authServer}auth/refresh`, { headers: refreshHeaders });
			if (data) {
				const {
					accessToken, refreshToken: newRefresh, expiryMs
				} = data;
				setToken(accessToken);
				window.localStorage.setItem("refreshToken", newRefresh);
				if (refreshTimeout.current) {
					clearTimeout(refreshTimeout.current);
				}
				refreshTimeout.current = setTimeout(getRefreshToken, (expiryMs * 1000) - 1000);
			}
			isRefreshing.current = false;
		}
	}, [getClientId, isLoggedIn]);

	useEffect(() => {
		getRefreshToken();
	}, [getRefreshToken]);

	useEffect(() => () => { // Clear timeout on unload
		if (refreshTimeout.current) {
			clearTimeout(refreshTimeout.current);
		}
	}, []);

	useEffect(() => {
		const usr = window.localStorage.getItem("gatsbyUser");
		if (usr) {
			try {
				const decoded = JSON.parse(usr);
				if (!_.isEqual(user, decoded)) {
					setUser(decoded);
				}
			}
			catch (e) {
				console.error("ERROR", e);
				presentDialog(infoDialog("Error", `Invalid token, please log in again ${e}`));
			}
		}

		if (_.isEmpty(user)) {
			checkLogin();
		}
	}, [checkLogin, presentDialog, user]);

	const customHeaders = useMemo(() => ({
		accept: "application/json",
		Authorization: `Bearer ${token}`,
		"X-Client-Version": packageJson.version
	}), [token]);

	const logout = useCallback(async () => {
		const clientId = await getClientId();
		const header = customHeaders;
		header["x-client-id"] = clientId;
		await axios.delete(`${authServer}auth/logout`, { headers: header });
		if (isBrowser) {
			window.localStorage.removeItem("gatsbyUser");
			window.localStorage.removeItem("refreshToken");
			window.localStorage.removeItem("lastProject");
		}
		clearInterval(intRef.current);
		clearInterval(refreshTimeout.current);
		intRef.current = null;
		setUser(undefined);
		setToken(masterKey);
	}, [customHeaders, getClientId, isBrowser]);

	const appleVerify = useCallback(async ({ auth, callback }) => {
		try {
			const clientId = await getClientId();
			const header = customHeaders;
			header["x-client-id"] = clientId;
			const { data } = await axios.post(`${authServer}auth/apple/verify`, auth, { headers: header });
			const { accessToken, refreshToken } = data;
			setTimeout(() => setToken(accessToken), logonDelay);
			window.localStorage.setItem("refreshToken", refreshToken);
			callback?.({ success: true, tokenInfo: data });
		}
		catch (error) {
			callback?.({ success: false, error });
			presentDialog(infoDialog("Apple sign-in failed", `Could not complete Apple sign-in. ${error.message}`));
		}
	}, [customHeaders, getClientId, presentDialog]);

	const cancelSocket = () => {
		socketIOClient.current?.close();
		socketIOClient.current = undefined;
		window.localStorage.removeItem("loginSocketExp");
		if (closeTimeoutRef.current) {
			clearTimeout(closeTimeoutRef.current);
			closeTimeoutRef.current = undefined;
		}
	};

	const checkSocket = useCallback(async () => {
		const expiry = window.localStorage.getItem("loginSocketExp");
		return !!expiry && expiry > Date.now();
	}, []);

	// eslint-disable-next-line default-param-last
	const waitForSocket = useCallback(async (useToken = true, callback) => {
		try {
			const clientId = await getClientId();
			const expiry = window.localStorage.getItem("loginSocketExp");
			const diff = expiry && expiry - Date.now();
			if (socketIOClient.current) {
				return;
			}
			socketIOClient.current = io(authServer, { transports: ["websocket"], auth: { clientId }, autoConnect: false });
			if (useToken && !expiry) {
				window.localStorage.setItem("loginSocketExp", Date.now() + SOCKET_TIMEOUT);
			}

			closeTimeoutRef.current = setTimeout(() => {
				socketIOClient.current?.close();
				window.localStorage.removeItem("loginSocketExp");
				socketIOClient.current = undefined;
				closeTimeoutRef.current = undefined;
			}, diff ?? SOCKET_TIMEOUT);

			socketIOClient.current?.on("login", (data) => {
				socketIOClient.current?.emit("ack-logon");
				try {
					const tokenInfo = JSON.parse(data);
					if (useToken) {
						const { accessToken, refreshToken } = tokenInfo;
						setTimeout(() => setToken(accessToken), logonDelay);
						window.localStorage.setItem("refreshToken", refreshToken);
					}
					callback?.({ success: true, tokenInfo });
				}
				finally {
					socketIOClient.current?.close();
					socketIOClient.current = undefined;
					window.localStorage.removeItem("loginSocketExp");
					if (closeTimeoutRef.current) {
						clearTimeout(closeTimeoutRef.current);
						closeTimeoutRef.current = undefined;
					}
				}
			});
			socketIOClient.current.connect();
		}
		catch (e) {
			console.error("Socket open error", e);
			presentDialog(infoDialog("Socket Error", "Failed to open socket for logon, please try logging on again"));
		}
	}, [getClientId, presentDialog]);

	const sendMagicLink = useCallback(
		async ({ email, useToken = true, callback }) => {
			try {
				const clientId = await getClientId();
				const header = customHeaders;
				const redirect = `${webUrl}login-settled`;
				header["x-client-id"] = clientId;
				waitForSocket(useToken, callback);
				await axios.post(`${authServer}auth/magic/authorize`, { email, host: authServer, redirect }, { headers: header });
			}
			catch (error) {
				if (closeTimeoutRef.current) {
					clearTimeout(closeTimeoutRef.current);
				}
				cancelSocket();
				callback({ success: false, error });
			}
		},
		[customHeaders, getClientId, waitForSocket]
	);

	const values = useMemo(() => ({
		isLoggedIn,
		user,
		logout,
		token,
		customHeaders,
		appleVerify,
		checkSocket,
		sendMagicLink
	}), [customHeaders, isLoggedIn, logout, token, user, appleVerify, checkSocket, sendMagicLink]);

	return (
		<LoginContext.Provider value={values}>{children}</LoginContext.Provider>
	);
};

const useAuth = () => {
	const context = useContext(LoginContext);
	if (context === undefined) {
		throw new Error("Must use Login Context inside the provider!");
	}

	return context;
};

export default useAuth;
