import React, {
	useState, useCallback, useRef, createContext, useContext, useMemo
} from "react";
import _ from "lodash";
import uuid4 from "common/uuidv4";
import axios from "axios";
import { webUrl } from "common/app-settings";
import useAuth from "./useAuth";

export const TreeContext = createContext({});

export const TreeProvider = ({ children }) => {
	const rootFolderIdRef = useRef(uuid4());
	const { customHeaders } = useAuth();
	const [folderTree, setFolderTree] = useState();

	const checkChildren = useCallback((treeNode, nodeId, resolve) => {
		if (!treeNode.name) {
			Object.values(treeNode).forEach((c) => {
				if (c.id === nodeId) {
					resolve(c);
				}
				if (c.children && Object.keys(c.children).length > 0) {
					checkChildren(c.children, nodeId, resolve);
				}
			});
		}
		else {
			if (treeNode.id === nodeId) {
				resolve(treeNode);
			}

			if (treeNode.children && Object.keys(treeNode.children).length > 0) {
				checkChildren(treeNode.children, nodeId, resolve);
			}
		}
	}, []);

	const findTreeNode = useCallback((nodeId, searchTree) => new Promise((resolve, reject) => {
		try {
			checkChildren(searchTree[rootFolderIdRef.current], nodeId, resolve);
		}
		catch (e) {
			reject(e);
		}
	}), [checkChildren]);

	const fetchFolder = useCallback(async (parentId, refetch = false) => {
		const newTree = _.cloneDeep(folderTree);
		const node = await findTreeNode(parentId, newTree);
		const { nodePath, expanded, depth } = node;
		if (!expanded || refetch) {
			axios.get(`${webUrl}${nodePath}`, { headers: customHeaders }).then(({ data: fileInfo }) => {
				const treeTemp = {};
				fileInfo?.forEach?.(async (item) => {
					const { name: itemName, type } = item;
					const id = uuid4();
					treeTemp[id] = {
						...item,
						nodePath: `${nodePath}${itemName}${type === "directory" ? "/" : ""}`,
						id,
						depth: depth + 1
					};
				});
				// eslint-disable-next-line no-param-reassign
				node.children = treeTemp;
				node.expanded = true;
				setFolderTree(newTree);
			}).catch((e) => {
				console.error("Couldn't get the folder listing", e);
			});
		}
		else {
			node.expanded = false;
			setFolderTree(newTree);
		}
	}, [customHeaders, findTreeNode, folderTree]);

	const initialiseTree = useCallback((basePath) => {
		setFolderTree({
			[rootFolderIdRef.current]: {
				name: "Documents", type: "directory", mtime: "", id: rootFolderIdRef.current, nodePath: `${basePath}/docs/`, depth: 0
			}
		});
	}, []);

	const resetTree = useCallback(() => {
		setFolderTree(undefined);
	}, []);

	const values = useMemo(() => ({
		initialiseTree,
		folderTree,
		fetchFolder,
		resetTree
	}), [fetchFolder, folderTree, initialiseTree, resetTree]);

	return <TreeContext.Provider value={values}>{children}</TreeContext.Provider>;
};

const useFolderTree = () => {
	const context = useContext(TreeContext);
	if (context === undefined) {
		throw new Error("useFolderTree must be used within a TreeProvider");
	}

	return context;
};

export default useFolderTree;
