exports.components = {
  "component---src-pages-admin-create-edit-project-js": () => import("./../../../src/pages/admin/createEditProject.js" /* webpackChunkName: "component---src-pages-admin-create-edit-project-js" */),
  "component---src-pages-admin-create-edit-user-js": () => import("./../../../src/pages/admin/createEditUser.js" /* webpackChunkName: "component---src-pages-admin-create-edit-user-js" */),
  "component---src-pages-admin-list-projects-js": () => import("./../../../src/pages/admin/listProjects.js" /* webpackChunkName: "component---src-pages-admin-list-projects-js" */),
  "component---src-pages-admin-list-users-js": () => import("./../../../src/pages/admin/listUsers.js" /* webpackChunkName: "component---src-pages-admin-list-users-js" */),
  "component---src-pages-admin-project-link-list-js": () => import("./../../../src/pages/admin/ProjectLinkList.js" /* webpackChunkName: "component---src-pages-admin-project-link-list-js" */),
  "component---src-pages-client-js": () => import("./../../../src/pages/client.js" /* webpackChunkName: "component---src-pages-client-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-login-settled-js": () => import("./../../../src/pages/login-settled.js" /* webpackChunkName: "component---src-pages-login-settled-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */)
}

